import { EditorState, TextSelection } from "prosemirror-state";

import { toggleMark } from "prosemirror-commands";
import { DOMParser } from "prosemirror-model";
//import { singleLineSchema, multiLineSchema, richTextSchema } from "./schemas";
//import { corePlugins } from '../helpers/plugins';

import { keymap } from "prosemirror-keymap";
import { baseKeymap } from "prosemirror-commands";
import { history, redo, undo } from "prosemirror-history";
import { Schema } from "prosemirror-model";
//import {timedInputRules} from "./breaktimed";
import {timedPlugin} from "./timed-plugin";

// Class timed needs to be "inclusive"  only of non-space content
//  this is probably a plugin that does  appendTransaction with 
// a check for spaces and chooses weather to create a new node instead


let startArray = [];

const getCWDocSchema = function() {
  return new Schema({
    nodes: {
      text: {
        draggable: false,
        group: "inline"
      },
      paragraph: {
        draggable: false,
        selectable: false,
        group: "block",
        content: "inline*",
        toDOM() {
          return ["p", 0];
        },
        parseDOM: [{ tag: "p" }]
      },
      doc: {
        selectable: false, 
        draggable: false,
        content: "block+"
      }
    },
    marks: {
      timed: {
        attrs: { start: { default: "" }, end: { default: "" } },
        toDOM(node) {
          return [
            "span",
            {
              "data-starttime": node.attrs.start,
              "data-endtime": node.attrs.end,
              class: "timed",
              title: "timed"
            }
          ];
        },
        parseDOM: [
          {
            tag: "span",
            getAttrs(dom) {
              if (
                !dom.getAttribute("class") ||
                dom.getAttribute("class").toLowerCase() != "timed"
              ) {
                return false;
              }
              startArray.push(dom.getAttribute("data-starttime"));
              return {
                start: dom.getAttribute("data-starttime"),
                end: dom.getAttribute("data-endtime")
              };
            }
          }
        ],
        inclusive: false,
        inclusiveLeft:  false,
      },
      speaker: {
        attrs: { id: { default: "" } },
        toDOM(node) {
          return [
            "span",
            {
              "data-id": node.attrs.id,
              class: "speaker",
              title: "Speaker #" + node.attrs.id
            }
          ];
        },
        parseDOM: [
          {
            tag: "span",
            getAttrs(dom) {
              if (
                !dom.getAttribute("class") ||
                dom.getAttribute("class").toLowerCase() != "speaker"
              ) {
                return false;
              }
              return { id: dom.getAttribute("data-id") };
            }
          }
        ],
        inclusive: false
      },
      comment: {
        attrs: { id: { default: "" } },
        toDOM(node) {
          return [
            "span",
            {
              "data-id": node.attrs.id,
              class: "comment",
              title: "comment #" + node.attrs.id
            }
          ];
        },
        parseDOM: [
          {
            tag: "span",
            getAttrs(dom) {
              if (
                !dom.getAttribute("class") ||
                dom.getAttribute("class").toLowerCase() != "comment"
              ) {
                return false;
              }
              return { id: dom.getAttribute("data-id") };
            }
          }
        ],
        inclusive: false
      }
    }
  });
};


export const cwDocSchema = getCWDocSchema();

//  exectue the function in place to create a function with cwDocSchema in the closure
//TODO: collect speakers to use in the speaker selection menu
const toggleSpeakerCommand = toggleMark(cwDocSchema.marks.speaker);

export const toggleSpeaker = function(state, dispatch) {
   console.log("toggling mark speaker", state, dispatch);
   let retval;
   if (dispatch) {
    retval= state.doc.cut(state.selection.from, state.selection.to).textContent;   
    toggleSpeakerCommand(state,dispatch)
  };
  return  retval;
};




/**
 * Takes a CW formatted 'content' and inserts it at the end of the editor
 * 
 * @param {*} state EditorState
 * @param {*} dispatch EditorView.Dispatch
 * @param {*} editorview EditorView
 * @param {*} content CW formatted content
 */
export function insertNodeAtEnd (state, dispatch, editorview, content) {
  let node = DOMParser.fromSchema(cwDocSchema).parse(content)
  console.log("insertNodeAtEnd "+content, node    ,state.tr, dispatch);

  if (dispatch) dispatch(state.tr.insert(state.tr.doc.content.size, node).scrollIntoView());
 return true   
}

export function insertName (state, dispatch, editorview, name) {
  console.log("insertName "+name, dispatch);
   let newtext = document.createTextNode(name);
   let span = document.createElement("span");
   span.setAttribute("class", "speaker");
   span.appendChild(newtext); 
   let timed = state.schema.text(name, [state.schema.marks.speaker.create()] );
//  insertNodeAtEnd(state, dispatch, editorview,span);
if (dispatch) {
  dispatch(state.tr.replaceSelectionWith(timed, false));

  // dispatch(state.tr.insert(state.tr.doc.content.size, timed).scrollIntoView());
}
return true   
//    document.createTextNode('<span class="speaker">'++'</span>'))
}

export const createTranscriptionEditor = (content = "", plugins = []) => {
  let doc, selection;

  

  if (content) {
    doc = DOMParser.fromSchema(cwDocSchema).parse(content);
    console.log("content", content, doc);

    selection = TextSelection.atEnd(doc);
  }else{
    console.log("no content", content)

  }

  if (window) {
    window.startArray = startArray;
  }

  return EditorState.create({
    schema: cwDocSchema,
    doc,
    selection,
    plugins: [
      history(),
      keymap({
        "Mod-z": undo,
        "Mod-y": redo,
        "Mod-Shift-z": redo,
        "Mod-b": toggleSpeaker(),
        "Mod-B": toggleSpeaker()
      }),
      keymap(baseKeymap),
      timedPlugin(),
//      timedInputRules(cwDocSchema),
      ...plugins
    ]
  });
};
