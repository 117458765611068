import { Plugin, PluginKey, TextSelection, Selection } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';


/**
 * @returns {Plugin}
 */
export function timedPlugin() {

let plugin =   new Plugin({
    key: new PluginKey('timed'),
    view() {
        return {
          update: (view, prevState) => {
         //   console.log("update",view, prevState )
            if (!view || ! prevState){
                console.log("no ", view, prevState);

                return    ;    
            }   
            const prev = this.key.getState(prevState);
            const next = this.key.getState(view.state);
            if (!prev || ! next){
                console.log("no2 ", prev, next);
                return    ;    
            }   
            console.log("prev,next",prev,next);
            // See how the state changed
            const moved = prev.active && next.active && prev.range.from !== next.range.from;
            const started = !prev.active && next.active;
            const stopped = prev.active && !next.active;
            const changed = !started && !stopped && prev.text !== next.text;
            console.log(moved, changed);
//            debugger;

          }
        }
    },
    // view() {
    //   return {
    //     update: (view, prevState) => {
    //     }


    //   }
    // },
    state: {
        /**
         * Initialize the plugin's internal state.
         *
         * @returns {Object}
         */
        init() {
          return {
              version: 1.0
          }
        },
        //apply(tr, prev)   { return  1 },
        apply(tr, prev) {
            let stored = tr.getMeta(this)
            if (stored) return stored
            return tr.selectionSet || tr.docChanged ? null : prev
          }
    },
    props: {
        handleTextInput(view, from, to, text)   {
            return run(view, from, to, text, plugin)
        },
        handleDOMEvents: {
            compositionend: (view) => {
              setTimeout(() => {
                let {$cursor} = view.state.selection
                if ($cursor) run(view, $cursor.pos, $cursor.pos, "", rules, plugin)
              })
            }
          }
    }
    // ,
    // apply(tr, prev) {

    // }


  })
  return plugin;

  
}

const MAX_MATCH = 500;


// rules -- if we are dealing with a timed before the new text or after 
// and there are no spaces -- we extend the timed
function run(view, from, to, text,  plugin) {
    /// don't interupt typing a char
    console.log("run")
    if (view.composing) return false
    // don't extend into a space
    console.log("run compose")

    if (text.match(/^\s+$/))  return false
    console.log("run spoace")

    let hasSpace = text.match(/\s+/);

    let state = view.state, $from = state.doc.resolve(from), $to = state.doc.resolve(to)
    let tr =state.tr;
    if ($from.parent.type.spec.code) return false
    console.log("run code")


    // Appending text to a node before the text
    let textBefore = "";
    let nodeBefore;
    var extendBefore = 0 ;
    let timedBefore ;
    if ($from.parentOffset){
        $from.parent.nodesBetween(
            Math.max(0, $from.parentOffset - 1),
            $from.parentOffset, 
            function(node,start){
                console.log("nodeBefore between", nodeBefore, node);
                nodeBefore = node;
        })
    }

    if (nodeBefore) {
        console.log("nodeBefore exists");
        textBefore = $from.parent.textBetween(Math.max(0, $from.parentOffset - 1), $from.parentOffset,
        null, "\ufffc") 
    // is not a space, and is timedBefore
       console.log("textBefore", textBefore, textBefore.match(/^\s+/)) ;

        timedBefore = nodeBefore.marks.find( function  (item) { if (item.type.name == "timed") return true  ;  });
        let beforeSpace = textBefore.match(/\s+$/) ;
        if (beforeSpace == null){     
            if (timedBefore)  {   
             extendBefore =1
            }
        }
    }





    var extendAfter = 0 ;
    // is not a space, and is timed
    let timedAfter;
    let textAfter = "";
    let nodeAfter;
    try {
        $to.parent.nodesBetween( 
                $to.parentOffset,
                Math.max(0, $to.parentOffset + 1) ,
                function(node){
                    console.log("nodeAfter between", nodeAfter, node);
                    nodeAfter = node;
                }
        )
    }catch(err) {
            console.log("Error ", err);
    }
    


    if (nodeAfter){
        console.log("nodeAfter exists");
        textAfter = $to.parent.textBetween(
            $to.parentOffset,
            Math.max(0, $to.parentOffset + 1)) 
        timedAfter = nodeAfter.marks.find( (item) => { if (item.type.name == "timed") return true  ;  }       ) ; 
        console.log("textAfter", textAfter, textAfter.match(/^\s+/)) ;
        if (textAfter.match(/^\s+/) == null) {      
            console.log("textAfter is a space", textAfter);
            if (timedAfter)  {  
                console.log("timedAfter exists");   
                extendAfter =1
            }
        }
    }




//---------------------------






    if (extendBefore && extendAfter){
//        this will automatically work
        return false

    }
  //  debugger;

    if (extendBefore){
        //extends
        console.log("extendBefore ");
        tr.addStoredMark(timedBefore); // Do not continue with mark.
        tr.insertText(text);
        view.dispatch(tr.setMeta(plugin, {transform: tr, from, to, text}))
        return true

    }
    if (extendAfter){
        console.log("extendAfter ");

        tr.addStoredMark(timedAfter); // Do not continue with mark.
        tr.insertText(text);
        view.dispatch(tr.setMeta(plugin, {transform: tr, from, to, text}))
        return true
    }
    //for (let i = 0; i < rules.length; i++) {
      //let match = rules[i].match.exec(textBefore)
      //let tr = match && rules[i].handler(state, match, from - (match[0].length - text.length), to)
//      if (!tr) continue
//      view.dispatch(tr.setMeta(plugin, {transform: tr, from, to, text}))
  //    return true
//    }
    console.log("returning false ");

    return false
  }
  



  