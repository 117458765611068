import App from './App.svelte';
//Version: {version} - {date}
let domContent;
let dataArray;
domContent =document.getElementById('cw-content');
domContent.innerHTML=domContent.innerHTML.trim();


var targetLoc = document.getElementById('cw-target') || document.body;
let instructionsContentEl =document.getElementById('cw-instructions')  ;
var instructionsContent ="";
if (instructionsContentEl){
	instructionsContent =instructionsContentEl.innerHTML
}



let props =  {
	content: domContent,
	instructions: instructionsContent,
	audio: domContent.dataset.url,
	customer: domContent.dataset.customer,
	title: domContent.dataset.title,
	objid: domContent.dataset.objid,
	verbatim: domContent.dataset.verbatim,
	notes: domContent.dataset.notes,  
	assignmentId:  domContent.dataset.assignmentid,  
	populate:  domContent.dataset.populate,
	nameslist:  domContent.dataset.nameslist,
};
console.log("main props", props);
const app = new App({
	target: targetLoc,
	props: props
});
export default app;
