jQuery(document).ready(
    function(){
        jQuery('.star').each(function(){
            var aftag = $(this).siblings(".afid").first(); 
            var afid = aftag.val();
            var rating = $('#rating_' + afid).val();
            
            $(this).raty(
                {  precision:  false,
                   half:  false,
                   path:  'https://d1kdyzv1uct7uf.cloudfront.net/js/lib/raty/img/',
                   target:     '#rating_' + afid,
                   targetKeep: true,
                   number:    5,
                   start:     rating,
                   targetType: 'number',
                   click: function(score) {
                       jQuery.ajax({
                               url: "/store/customer/save_rating",
                               data: {"rating": score, "afid": afid ,"output" : "json"},
                               dataType: "json"
                           }
                                  );
                       jQuery('#feedback_afid').val(afid);
                       jQuery('#opaque').show();      
                       jQuery('#feedback').show();      
                       if (rating > 3) {
                           jQuery('#share').show();
                       }
                   }
                }

            );
        });
        $("#save_feedback_form").submit(
            function(event) {
                 event.preventDefault();
                jQuery('#opaque').fadeOut();
                jQuery('#feedback').fadeOut();
                jQuery('#share').hide();
                
                
                jQuery.post(
                    $(this).attr( 'action' ),
                    $(this).serialize()
                );
                jQuery('#feedback_text').val("");
                jQuery('#feedback_afid').val("");

       });
    }
);
