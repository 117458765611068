var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
//    console.log("getCookie", ca); 
                              

    for(var i=0; i<ca.length; i++) {        
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}

// NO JSON Cart count if static cart count
jQuery.ajax({type:'POST',
             url:  location.protocol +'//' + location.host + "/store/customer/jsonstatus",
             data: ({output:"json"}), 
             dataType: 'jsonp',
             cache: false, // don't cache the result
             contentType: "application/json", //tell the server we're looking for json
             success: 
             function (data){
//                 console.log("success", data);

                 if (!jQuery(".cartitemcount").length)  {
                     if (data.cart_count &&  data.cart_count != 0 ){
                         jQuery('#cart_item_badge').text(data.cart_count);
                     }
                 }



                 
                 

                 // email +  data.email
                 if (data.email){
                     console.log("Logged IN data email "+data.email);                         
                     jQuery("#loggedinnav_accountlink").text(data.email);
                     jQuery(".loggedoutnav").hide();
                     jQuery(".loggedinnav").show();
                     if (!data.name){
                         data.name=data.email;
                     }
                 }else{
                     console.log("NOT logged in");
                     jQuery("#accountmenu").css({"display": "list-item"});
                     
                     if (getCookie("email")){
                         console.log("email ", getCookie("email"), data.email );
                         jQuery('#loginform-email').val(decodeURIComponent(getCookie("email")));
                     }
                     jQuery(".loggedoutnav").show();
                     jQuery(".loggedinnav").hide();                     
//                   jQuery(".loginform").hide(300);                     
                     jQuery("#loggedinnav_accountlink").text( "Account");
                 }




                 
             },

             error: function (xhr, ajaxOptions, thrownError){
                 try{

                     console.log("An error in do_spellcheck XML", xhr, ajaxOptions, thrownError);
                 } catch(err)  {
                     //bury this in places that don't have a console
                 }
                 try{    
                     if(xhr.status === 0){
                         console.log('You are offline!!\n Please Check Your Network.');
                     }else if(xhr.status==404){
                         console.log('Requested URL not found.');
                     }else if(xhr.status==500){
                         console.log('Internel Server Error.');
                     }else {
                         console.log('Unknown Error.\n'+xhr.responseText  + " " + ajaxOptions);
                     }
                 } catch(err)  {
                     //bury this in places that don't have a console
                 }
             }
            }
           );
