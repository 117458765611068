import { Plugin, PluginKey, TextSelection, Selection } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
//import {suggestionList} from './store.js'




function triggerComplete() {
  /**
   * @param {ResolvedPos} $position
   */
  return ($position, state) => {
    const regexp =  /^\S+| \S*/mg;

    // Lookup the boundaries of the current node
    const textFrom = $position.before();
    const textTo = $position.end();



    const text = $position.doc.textBetween(textFrom, textTo, '\0', '\0');
    
    let match;
    console.log(`matcher '${text}' '${regexp}'`, textFrom, textTo, $position.pos, text.length);
   
    // end of text match
    if (textTo == $position.pos) {  
        console.log("matched on end of text");
        const prevText = $position.doc.textBetween($position.pos -1 , $position.pos, '\0', '\0');
        if (!prevText.match(/\S/)){
          return    { range: { from: textTo, to: textTo}, text: "" } 
        }
        console.log("end of text valid - previous char is ", prevText);
    } 

    // test to see if the cursor is in the middle of a typed word (in which case no suggestions)
    if (textTo >= $position.pos) {  
      const nextText = $position.doc.textBetween($position.pos, $position.pos+1, '\0', '\0');
      console.log("Next text is ", nextText);
      if (nextText.match(/\S/)){
        console.log("matched middle of text, faiure");
        return   
      }
    } 


// skip the match if the match is before the cursor or start position
    while ((match = regexp.exec(text))) {
      // The absolute position of the match in the document
      const from = match.index + $position.start();
      let to = from + match[0].length;

      // If the $position is located within the matched substring, return that range
      if (from < $position.pos && to >= $position.pos) {
        console.log(`triggerComplete returning match '${match}'`)
        return { range: { from, to }, text: match[0] };
      }
    }
  };
}


// /**
//  * Create a matcher that matches when a specific character is typed. Useful for @mentions and #tags.
//  *
//  * @param {String} char
//  * @param {Boolean} allowSpaces
//  * @returns {function(*)}
//  */
// export function triggerCharacter(char, { allowSpaces = false }) {
//   /**
//    * @param {ResolvedPos} $position
//    */
//   return $position => {
//     // Matching expressions used for later
//     const suffix = new RegExp(`\\s${char}$`);
//     const regexp = allowSpaces
//       ? new RegExp(`${char}.*?(?=\\s${char}|$)`, 'g')
//       : new RegExp(`(?:^)?${char}[^\\s${char}]*`, 'g');

//     // Lookup the boundaries of the current node
//     const textFrom = $position.before();
//     const textTo = $position.end();

//     const text = $position.doc.textBetween(textFrom, textTo, '\0', '\0');

//     let match;

//     while ((match = regexp.exec(text))) {
//       // Javascript doesn't have lookbehinds; this hacks a check that first character is " " or the line beginning
//       const prefix = match.input.slice(Math.max(0, match.index - 1), match.index);
//       if (!/^[\s\0]?$/.test(prefix)) {
//         continue;
//       }

//       // The absolute position of the match in the document
//       const from = match.index + $position.start();
//       let to = from + match[0].length;

//       // Edge case handling; if spaces are allowed and we're directly in between two triggers
//       if (allowSpaces && suffix.test(text.slice(to - 1, to + 1))) {
//         match[0] += ' ';
//         to++;
//       }

//       // If the $position is located within the matched substring, return that range
//       if (from < $position.pos && to >= $position.pos) {
//         return { range: { from, to }, text: match[0] };
//       }
//     }
//   };
// }

/**
 * @returns {Plugin}
 */
export function suggestionsPlugin({
  matcher = triggerComplete(),
  suggestionClass = 'ProseMirror-suggestion',

  onExit = () => false,
  onKeyDown = () => false,
  debug = false,
  suggestionsArray = []
}) {
  return new Plugin({
    key: new PluginKey('suggestions'),

    view() {
      return {
        update: (view, prevState) => {
          const prev = this.key.getState(prevState);
          const next = this.key.getState(view.state);
          console.log("prev,next",prev,next);
          // See how the state changed
          const moved = prev.active && next.active && prev.range.from !== next.range.from;
          const started = !prev.active && next.active;
          const stopped = prev.active && !next.active;
          const changed = !started && !stopped && prev.text !== next.text;

          // Trigger the hooks when necessary
          if (stopped || moved) onExit({ view, range: prev.range, text: prev.text });
          if (changed && !moved) startOrChange({ view, range: next.range, text: next.text ,  state: next, suggestionsArray: suggestionsArray });  // change
          if (started || moved) startOrChange({ view, range: next.range, text: next.text, state: next, suggestionsArray: suggestionsArray  });

          if ( !stopped && !next.active ) {
              console.log("Exting") 
              onExit({ view, range: prev.range, text: prev.text });
          }

        },
      };
    },

    state: {
      /**
       * Initialize the plugin's internal state.
       *
       * @returns {Object}
       */
      init() {
        return {
          active: false,  // are we showign a suggestion
          range: {}, // the postion in the TR  that the suggestiuon is matching
          text: null,   // text that the suggestion is matching
          lastUsedIndex: 0,  //this is used to see if we moved too far forward
          prevIndex: 0,  // this is used to move us forward in the suggestion list
          suggestion: null,  // the partial suggestion we show
          suggestionData: null, // thje suggestion that actually gets inserted inthe DOM with all the meta data
          escaped: false // the user has escaped this match
        };
      },

      /**
       * Apply changes to the plugin state from a view transaction.
       *
       * @param {Transaction} tr
       * @param {Object} prev
       *
       * @returns {Object}
       */
      apply(tr, prev) {
        const { selection } = tr;
        const next = { ...prev };
        console.log("Apply")
        // We can only be suggesting if there is no selection
        if (selection.from === selection.to) {
          // Reset active state if we just left the previous suggestion range
          if (selection.from < prev.range.from || selection.from > prev.range.to) {
            console.log("Apply: set inactive");
            next.active = false;

          }

          // Try to match against where our cursor currently is
          const  $position= selection.$cursor;
          console.log("Apply: $position", selection.constructor.name, $position.constructor.name,   $position.pos, $position.start(), $position.end(),  selection.from, selection.to, selection.textOffset,   selection.$anchor, selection.anchor);

          // const textFrom = $position.before();
          // const textTo = $position.end(); 
          // const text = $position.doc.textBetween(textFrom, textTo, '\0', '\0');
          // if (text.match(/  $/)){
          //   console.log("triggerComplete caught space completion")
          //        state = commitSuggestion(  view, this);

          //   return;
          // }

          let match;
          if (!next.escaped) {
            match = matcher($position) 
          }
          // If we found a match, update the current state to show it
          if (match) {
            next.active = true;
            next.range = match.range;
            next.text = match.text;
          } else {
            next.active = false;
          }
        } else {
          next.active = false;
        }

        // Make sure to empty the range if suggestion is inactive
        if (!next.active) {
          next.range = {};
          next.text = null;
          
          next.suggestion = "";
          next.suggestionData = null;
        }

        return next;
      },
    },

    props: {
      /**
       * Call the keydown hook if suggestion is active.
       *
       * @param view
       * @param event
       * @returns {boolean}
       */
      handleKeyDown(view, event) {
        let state = this.getState(view.state);
        let plugin = this;

        console.log(`onKey '${event.key}'`, event.key, event);
        if ( event.key == "Tab" ) {
          //tab should never tab out of the container
          event.preventDefault();
        }
        
        if ( state.escaped  &&  (event.key == "Tab" || event.key == " " ) ){
          console.log("onKey Down  ESCAPED mode ",  event.key ,  event, state);
          state.escaped = false;
          state.active  = false;  // seriously this should ALWAYS be true, but best to be sure
          const tr = view.state.tr;
          view.dispatch(tr);
        }else  if(!state.active && (event.key == " " ) ){
          //we just typed a word
          if (          state.lastUsedIndex != state.prevIndex ){
            //reset to the last used to previous
            state.lastUsedIndex = state.prevIndex;
          } else { 
            // dang failed to match  2x in a row
            //TODO: implement a array of last used items and pop them?        
            //TODO: sync array based off of next timed item if there is a future timed item?
            state.lastUsedIndex = 0;
            state.prevIndex  = 0;
          }

        }
        if (!state.active) return false;

        console.log("onKey", event.key, plugin);
        if ((event.key == "Escape" ) && state.suggestionData) {
          // kill this and all suggestions for this word


          event.preventDefault();
          let state = plugin.getState(view.state);
          state.lastUsedIndex = state.prevIndex;

          state.suggestion = "";
          state.suggestionData = null;
          state.active = false;
          state.range = {};
          state.text = null;
          state.escaped = true;
          console.log("onKey Down key kill suggestion",  event.key ,  event, state);   
          const tr = view.state.tr;

          view.dispatch(tr);
       
          return true;
        }
       if ((event.key == "Tab" || event.key == " " ) && state.suggestionData) {
            console.log("onKey Down key",  event.key ,  event, state);
            event.preventDefault();
            state = commitSuggestion(  view, plugin);

            return true;
        }


        return false;



        //return onKeyDown({ view, event,  state,  plugin});
      },



      handleClick(view,pos,event){
        let state = this.getState(view.state);
        let plugin = this;
        console.log(`handleClick2 '${event.key}'`, pos, event);

        if (state.escaped){
          console.log("handleClick  ESCAPED mode ",  event.key ,  event, state);
          state.escaped = false;
          state.active  = false;  // seriously this should ALWAYS be true, but best to be sur
        }

        const tr = view.state.tr;
        view.dispatch(tr);


      },


      /**
       * Setup decorator on the currently active suggestion.
       *
       * @param {EditorState} editorState
       *
       * @returns {?DecorationSet}
       */
      decorations(editorState) {
        const { active, range,  suggestion } = this.getState(editorState);
        //console.log ("decoration running with ",active, suggestion)
        if (!active) return null;
        let widget = document.createElement("suggestion")
        if (suggestion){
          widget.innerText = suggestion;
        }
        return DecorationSet.create(editorState.doc, [

          Decoration.widget(range.to,  widget ),
          // Decoration.inline(range.from, range.to, {
          //   nodeName: 'span',
          //   class: suggestionClass,
          //   style: debug ? 'background: rgba(0, 0, 255, 0.05); color: blue; border: 2px solid blue;' : null,
          // }),
        ]);
      },
    },
  });

  function commitSuggestion( view, plugin) {
    let state = plugin.getState(view.state);
    if (! state.suggestionData) {
      console.warn("no suggestion, can't commit it ", state);
      return state
    }
    const tr = view.state.tr;
    const actualSuggestion = state.suggestionData;
    let prespacecount = 0;
    let postspacecount = 1;

    let suggestionText =  actualSuggestion.suggestion + " ";

    if (state && state.text && state.text.match(/^\s/)){
      if (actualSuggestion.class.match(/speaker/)){
        suggestionText = "\n" + suggestionText;


      }else{      
          suggestionText = " " + suggestionText;
      }
      prespacecount = 1;
    }


    let mark;
    if (actualSuggestion.class.match(/speaker/)){
      mark = view.state.config.schema.marks.speaker.create({ id: actualSuggestion.id })

    }else if(actualSuggestion.class.match(/timed/)){
      mark = view.state.config.schema.marks.timed.create({ id: actualSuggestion.id,  start: actualSuggestion.starttime ,end: actualSuggestion.endtime })
    }else if(actualSuggestion.class.match(/comment/)){
      mark = view.state.config.schema.marks.comment.create({ id: actualSuggestion.id})
    }


    console.log("HonKey commitSuggestion", actualSuggestion, prespacecount)
    const newTo =  ( state.range.from  +suggestionText.length ) ;
    tr.insertText( suggestionText, state.range.from, state.range.to);


    if (mark){
      tr.addMark(state.range.from + prespacecount, newTo - postspacecount , mark);
    }
    state.active = false;
    let maxSkipSize = 10

    // if just one word is way forward, just move forward by one, else we can do forward by 2
    if (state.lastUsedIndex  + maxSkipSize < actualSuggestion.suggestionsIndex +1
        &&
        state.prevIndex + maxSkipSize < actualSuggestion.suggestionsIndex 
      ){
      state.lastUsedIndex = state.lastUsedIndex  + 1
      state.prevIndex = actualSuggestion.suggestionsIndex;
    }else{
        state.prevIndex = state.lastUsedIndex;
        state.lastUsedIndex = actualSuggestion.suggestionsIndex +1 ;  
    }
    
    state.suggestion = "";
    state.suggestionData = null;
    console.log("HonKey  predispatch ", state, tr, plugin);
    
     view.dispatch(tr);

    console.warn("HonKeyDown Tab Done",  state, view.state.tr.$from);
    return state;
  }

  /* 
  *  if we are starting typing or changing a suggestion 
  * generate a new suggestion
  *   
  * 
  * */





  function startOrChange (args) {
    console.log("startOrChange 2", args.text, args.view.endOfTextblock("right"),   args,  );
    if (!args.view.endOfTextblock("right")  )
    {   
        console.log("exiting");
        return;
    }



    let s = args.text.replace(/^\s+/, '');
    console.log("match with no leading space: ",s,  args.state,args.state.lastUsedIndex )
    let suggested;
    let suggestedIndex = args.state.lastUsedIndex || 0 ;
    let stringReg = "^"+s;
    let suggestionsArray = args.suggestionsArray;

    if (s.length){


        let reg = new RegExp(stringReg); 
        console.log(reg,suggestedIndex ) ;
        suggestedIndex = suggestionsArray.findIndex((e,currIndex) => {  
                if (currIndex <=  suggestedIndex){return}
                if (!e.text){return}

                return e.text.match(reg) 
            }

        );
        
        if (suggestedIndex != null && suggestionsArray[suggestedIndex])  {
            suggested = suggestionsArray[suggestedIndex].text;

            suggested = suggested.replace(s,'')
            console.log("replace", suggested, stringReg, suggested.replace(stringReg,''),   suggestedIndex)
        }
    }
    else{
        suggested = suggestionsArray[suggestedIndex].text;
        suggestedIndex = suggestedIndex;
        console.log("No data yet, just using index to suggest next word",  suggested,suggestedIndex );

    }

    if (suggested){
        console.warn ("setting suggested1", suggested,suggestedIndex )
        args.state.suggestionData = {...suggestionsArray[suggestedIndex] };
        args.state.suggestionData.suggestionsIndex= suggestedIndex
        args.state.suggestionData.suggestion= suggestionsArray[suggestedIndex].text ;
        args.state.suggestion = suggested; 
        
        
        // if(args.state.suggestionData.class.match(/speaker/)){
        //   args.state.suggestion = "\n" + suggested;  // this is just part of the word if it's been partially typed

        // }else{
        //   args.state.suggestion = suggested;  // this is just part of the word if it's been partially typed

        // }

        console.warn ("setting suggested2", suggested,suggestedIndex, args.state.suggestionData  )
    }else{
      args.state.active=false;
    }

    ///  TYhis is one way of doing a suggestion

    const tr = args.view.state.tr;
    const { from,  to } = args.range;
    console.log("ssug in stat as  state", args.state, args.state.suggestion, args.state.suggestionData, suggestedIndex ,  from,  to);

  
    // tr.insertText(suggested, from,  to);
  //    args.view.dispatch(tr);
  //      next = suggestedIndex +1;   This can mot happen unless the match is accepted


    return false;
  }




}
