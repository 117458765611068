jQuery(document).ready(function(){
    jQuery('#checkall').click(function() {
        jQuery('.checkbox').prop("checked", true);
        
    });
    jQuery('#checknone').click(function() {
        jQuery('.checkbox').prop("checked", false);
    });
    jQuery('.trash').click(function() {
        jQuery('#deleteitem').val($(this).data("value"));
        jQuery('#deleteform').submit();
    });
});

