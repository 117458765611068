
import { Plugin, PluginKey } from 'prosemirror-state';

export function  saveRetrieveDocPlugin(storageKey) {
    return     new  Plugin(
      {
        key: new PluginKey('saveRetrieveDocPlugin'),
        state: {
          init(config, state) {
            const strContent = localStorage.getItem('te3-content-' + storageKey);
            console.log("init" + storageKey , strContent);
            if (strContent) {
              state.doc = state.schema.nodeFromJSON(JSON.parse(strContent));
              console.log("pulled content for " + storageKey );
            }
          },
          apply(tr,prev) {
            if (tr.docChanged) {
              console.log("saving" + storageKey );
              try {
              localStorage.setItem('te3-content-' + storageKey, JSON.stringify(tr.doc.toJSON()));
              } catch (e){
                console.log("error in localstorage", e)

              }
            } 
          },
        }
      }
      
      );
      
      
  }


